<template>
  <v-row data-testid="basal-once-daily-section">
    <v-col cols="12" sm="3" lg="3">
      <ReviewField
        :is-dose-value="true"
        :display-value="prescription.basal_once_daily.value"
        :dosing-display-unit="dosingDisplayUnit('basal_once_daily')"
        data-testid="basal-once-daily-value"
      >
        Initial <strong>daily</strong> dose
      </ReviewField>
    </v-col>
  </v-row>
</template>

<script>
import initialPrescriptionMixin from './initialPrescriptionMixin'
import ReviewField from '../shared/ReviewField'

export default {
  name: 'OnceDailyOverview',
  mixins: [initialPrescriptionMixin],
  components: { ReviewField },
}
</script>

<style scoped></style>
